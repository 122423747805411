<template>
  <div class="widgetContainer bg-white">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('card_activate_Title') }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form
        class="wise-form"
        ref="cardDetail"
        :model="formData"
        :rules="formRules">
        <el-form-item
          class="is-no-asterisk"
          prop="last4"
          :label="$t('card_activate_last4_lable')">
          <the-mask
            mask="####"
            class="el-input__inner"
            type="tel"
            autocomplete="off"
            name="last4"
            :masked="true"
            v-model="formData.last4" />
        </el-form-item>
        <el-form-item
          class="is-no-asterisk"
          prop="last4"
          :label="$t('card_activate_expDate_lable')">
          <the-mask
            mask="##/####"
            class="el-input__inner"
            type="tel"
            autocomplete="off"
            name="expirationDate"
            :masked="true"
            v-model="formData.expirationDate" />
        </el-form-item>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button type="primary" @click="next()" class="width-100 el-button__brand brand">
        {{ $t('next') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { TheMask } from 'vue-the-mask';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ActivateCardForm',
  components: {
    TheMask
  },
  computed: {
    ...mapGetters('card', ['getSelectedCard']),
    isFormValid() {
      return (
        this.formData.last4 !== '' &&
        this.formData.last4.length == 4 &&
        this.formData.expirationDate !== '' &&
        this.formData.expirationDate.length == 7
      );
    }
  },
  data() {
    return {
      formData: {
        last4: '',
        expirationDate: ''
      },
      formRules: {
        last4: [
          {
            required: true,
            message: 'last 4 digits of your Card is required'
          }
        ],
        expirationDate: [
          {
            required: true,
            message: 'Expiration Date is required'
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions('card', ['activate']),
    next() {
      const splitDate = this.formData.expirationDate.split('/');
      const expiryMonth = splitDate[0];
      const expiryYear = splitDate[1];
      const loader = this.showLoader();
      this.activate({
        cardId: this.getSelectedCard.id,
        payload: {
          last4: this.formData.last4,
          expiryMonth: expiryMonth,
          expiryYear: expiryYear
        }
      })
        .then(() => {
          this.drawerPush('CardActivated');
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    cancel() {
      this.drawerBack();
    }
  }
};
</script>
